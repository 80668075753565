<template>
  <div class="position-editor">
    <label v-if="label">{{ label }}</label>
    <div class="position-editor__fields">
      <TextInput v-model="position.x" label="X" type="number" small />
      <TextInput v-model="position.y" label="Y" type="number" small />
      <TextInput v-model="position.z" label="Z" type="number" small />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      position: this.value || {
        x: 0,
        y: 0,
        z: 0
      }
    }
  },
  watch: {
    position: {
      handler: function (newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.position-editor {
  label {
    margin: $space-xs 0;
  }

  &__fields {
    display: flex;
    gap: $space-s;

    .field-container {
      flex: 1;
    }
  }
}
</style>
