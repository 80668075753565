<template>
  <div class="spatial-editor">
    <button class="standard" type="button" @click="resetToDefaults">Reset to defaults</button>
    <div class="spatial-editor__control-group">
      <h4>General</h4>
      <v-checkbox
        v-model="settings.autoRotate"
        hide-details
        label="Auto Rotate?"
        @change="update"
      />
    </div>

    <div class="spatial-editor__control-group">
      <h4>Model</h4>
      <TextInput
        v-model="settings.model.scale"
        label="Scale"
        type="number"
        small
        @input="update"
      />
    </div>

    <div class="spatial-editor__control-group">
      <h4>Animation</h4>
      <v-checkbox
        v-model="settings.animation.enabled"
        hide-details
        label="Enable Animation?"
        @change="update"
      />
      <TextInput
        v-model="settings.animation.index"
        label="Animation index"
        type="number"
        small
        @input="update"
      />
    </div>

    <div class="spatial-editor__control-group">
      <h4>Controls</h4>
      <div class="spatial-editor__control-group__group">
        <TextInput
          v-model="settings.controls.minDistance"
          label="Minimum Distance"
          type="number"
          small
          @input="update"
        />
        <TextInput
          v-model="settings.controls.maxDistance"
          label="Maximum Distance"
          type="number"
          small
          @input="update"
        />
        <TextInput
          v-model="settings.controls.maxPolarAngle"
          label="Maximum Polar Angle"
          type="number"
          :min="0.0"
          :max="1.0"
          small
          @input="update"
        />
      </div>
    </div>

    <div class="spatial-editor__control-group">
      <h4>Camera</h4>
      <PositionEditor
        v-model="settings.camera.position"
        label="Initial Position"
        @input="update"
      />
    </div>

    <div class="spatial-editor__control-group">
      <h4>Lights</h4>
      <div class="light-editor" v-for="(light, index) in settings.lights" :key="`light-${index}`">
        <h5>Light {{ index }}</h5>
        <div class="spatial-editor__control-group__group">
          <TextInput
            v-model="settings.lights[index].color"
            label="Color"
            type="text"
            small
            @input="update"
          />
          <TextInput
            v-model="settings.lights[index].intensity"
            label="Intensity"
            type="number"
            small
            @input="update"
          />
          <TextInput
            v-model="settings.lights[index].distance"
            label="Distance"
            type="number"
            small
            @input="update"
          />
        </div>
        <v-checkbox
          v-model="settings.lights[index].animate"
          hide-details
          label="Animate Light?"
          @change="update"
        />
        <template v-if="settings.lights[index].animate">
          <PositionEditor
            v-model="settings.lights[index].animationRate"
            label="Animation Rate"
            @input="update"
          />
          <PositionEditor
            v-model="settings.lights[index].animationScale"
            label="Animation Scale"
            @input="update"
          />
        </template>
        <button class="standard" type="button" @click="settings.lights.splice(index, 1)">
          Delete Light {{ index }}
        </button>
        <br><br>
      </div>
      <button class="standard" type="button" @click="addLight">Add Light</button>
    </div>

    <div class="spatial-editor__control-group">
      <h4>Ground</h4>
      <v-checkbox
        v-model="settings.ground.disabled"
        hide-details
        label="Disable Ground Mesh?"
        @change="update"
      />
      <TextInput
        v-model="settings.ground.position.y"
        label="Y Position"
        type="number"
        small
        @input="update"
      />
    </div>

    <div class="spatial-editor__control-group">
      <h4>Post-Processing</h4>
      <v-checkbox
        v-model="settings.postProcessing.enabled"
        hide-details
        label="Enable Post-processing?"
        @change="update"
      />
      <template v-if="settings.postProcessing.enabled">
        <h5>Bloom</h5>
        <v-checkbox
          v-model="settings.postProcessing.bloom.enabled"
          hide-details
          label="Enable Bloom?"
          @change="update"
        />
        <template v-if="settings.postProcessing.bloom.enabled">
          <TextInput
            v-model="settings.postProcessing.bloom.threshold"
            label="Threshold"
            type="number"
            small
            @input="update"
          />
          <TextInput
            v-model="settings.postProcessing.bloom.strength"
            label="Strength"
            type="number"
            small
            @input="update"
          />
          <TextInput
            v-model="settings.postProcessing.bloom.radius"
            label="Radius"
            type="number"
            small
            @input="update"
          />
        </template>
        <h5>Gamma Correction</h5>
        <v-checkbox
          v-model="settings.postProcessing.gammaCorrection.enabled"
          hide-details
          label="Enable Gamma Correction?"
          @change="update"
        />
      </template>
    </div>
  </div>
</template>

<script>
import defaultSettings from '../common/default3dViewerSettings'
import PositionEditor from '../components/PositionEditor.vue'
export default {
  props: {
    value: {
      type: Object,
      default: () => defaultSettings
    }
  },
  components: {
    PositionEditor
  },
  data () {
    return {
      settings: this.value || defaultSettings
    }
  },
  methods: {
    resetToDefaults () {
      console.log('Resetting to defaults...')
      this.settings = JSON.parse(JSON.stringify(defaultSettings))
    },
    update () {
      console.log('Spatial settings update.')
      this.$emit('input', this.settings)
    },
    addLight () {
      this.settings.lights.push({
        color: '0xffffff',
        intensity: 3,
        distance: 50,
        animate: false,
        animationRate: {
          x: 0.1,
          y: 0.2,
          z: 0.3
        },
        animationScale: {
          x: 30,
          y: 40,
          z: 30
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.spatial-editor {
  &__control-group {
    margin: $space-m 0;
    &__group {
      display: flex;
      gap: $space-s;
      > * {
        flex: 1;
      }
    }
    h4 {
      margin-bottom: $space-xs;
    }
  }
}
</style>
