<!--
  Form used to mint a TokenContract
-->

<template>
  <div class="form-container mint-step">
    <div class="header">
      <BackIcon type="back" @click.native="back" color="#f4f4f4" />
      <h3 class="step-title accented">Define Token</h3>
    </div>
    <v-select
      v-model="chosenCollection"
      hide-details
      class="top-level-selector"
      label="Collection"
      :items="tokenContracts || []"
      item-text="name"
      item-value="id"
      return-object
      @change="chooseCollection"
    />
    <form>
      <TextInput
        v-model="mintingToken.name"
        label="Token Name"
        name="token-name"
        required
      />
      <v-select
        v-model="mintingToken.series"
        hide-details
        class="top-level-selector"
        label="Series"
        :items="allSeries || []"
        item-text="name"
        item-value="id"
        return-object
      />
      <template v-if="chosenCollection && chosenCollection.variants">
        <v-select
          v-model="mintingToken.typePrimary"
          :items="chosenCollection.variants.primary"
          item-text="name"
          item-value="id"
          return-object
          hide-details
          label="Primary Type"
        />
        <div class="input-container">
          <VariantsEditor
            v-model="newPrimaryVariants"
            mode="primary"
            label="Primary"
            :hideLabel="true"
            :saveButton="true"
            @save="saveNewVariants('primary')"
          />
        </div>
      </template>
      <template v-if="chosenCollection && chosenCollection.variants">
        <v-select
          v-model="mintingToken.typeSecondary"
          :items="chosenCollection.variants.secondary"
          item-text="name"
          item-value="id"
          return-object
          hide-details
          label="Secondary Type"
        />
        <div class="input-container">
          <VariantsEditor
            v-model="newSecondaryVariants"
            label="Secondary"
            :hideLabel="true"
            :saveButton="true"
            @save="saveNewVariants('secondary')"
          />
        </div>
      </template>
      <div class="input-container id">
        <div class="row">
          <TextInput
            v-model="computedId"
            disabled
            label="Token ID"
            name="token-id"
          />
          <TextInput
            v-model.number="mintingToken.order"
            type="number"
            label="Order"
            name="order"
          />
          <TextInput
            v-model.number="mintingToken.seriesOrder"
            type="number"
            label="Series Order"
            name="seriesOrder"
          />
        </div>
      </div>

      <!-- Required Media -->
      <MediaPicker
        v-model="mintingData.tokenThumbData"
        label="Token Thumb"
        uid="thumb"
        required
        :requiredWidth="540"
        :requiredHeight="540"
      />
      <MediaPicker
        v-model="mintingData.tokenImageData"
        label="Token Image"
        uid="image"
        required
        :maxWidth="1080"
        :maxHeight="1080"
      />

      <!-- Optional Media -->
      <MediaPicker
        v-model="mintingData.tokenFullImageData"
        label="Token Image Full"
        uid="full-image"
      />
      <MediaPicker
        v-model="mintingData.tokenUnclaimedImageData"
        label="Token Image Unclaimed"
        uid="unclaimed-image"
      />
      <MediaPicker
        v-model="mintingData.tokenVideoData"
        label="Token Video"
        uid="video"
      />
      <MediaPicker
        v-model="mintingData.tokenModelData"
        :viewerSettings="mintingToken.viewerSettings"
        label="Token Model"
        uid="model"
      />
      <SpatialSettingsEditor
        v-if="mintingData.tokenModelData"
        v-model="mintingToken.viewerSettings"
      />

      <TextArea
        v-model="mintingToken.description"
        label="Token Description"
        name="token-description"
      />
      <TextInput
        v-model="mintingToken.legalUrl"
        small
        label="Legal Document URL"
        name="legal-url"
      />
      <TextArea
        v-model="mintingToken.internalNotes"
        label="Internal Notes"
        name="internal-notes"
        height="50px"
      />
      <Properties
        ref="tokenProperties"
        v-model="mintingToken.properties"
        mode="token"
      >
        <div class="property-single input-container">
          <div class="row">
            <h6 class="label">Total Issuance</h6>
            <div class="fields">
              <input
                v-model="mintingToken.cap"
                type="number"
                class="small"
                placeholder="0 to 10,000"
              >
            </div>
          </div>
        </div>
      </Properties>
      <WalletsData />
      <ExtendingAttributes v-model="mintingToken.attributes" />
    </form>

    <div class="skip-cloudinary">
      <v-checkbox
        v-model="skipCloudinary"
        label="Skip Cloudinary Upload (for assets greater than ~7MB)"
      />
      <p v-if="skipCloudinary" class="error">
        If you skip the automated Cloudinary upload process, token media assets will need to be uploaded manually for the associated token to display properly in the web app.
      </p>
    </div>

    <div class="submit-buttons">
      <button class="standard" type="button" @click="initiateReview">Review</button>
      <ValidationErrors :errors="validationErrors" />
    </div>

    <MintReviewModal
      v-if="showingReview"
      :mintingData="reviewData"
      @close="showingReview = false"
      @confirm="publish"
    />
  </div>
</template>

<script>
import { doc, setDoc } from 'firebase/firestore'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import MintReviewModal from '@/components/modals/MintReviewModal'
import SpatialSettingsEditor from '@/components/SpatialSettingsEditor'

export default {
  components: {
    MintReviewModal,
    SpatialSettingsEditor
  },
  data () {
    return {
      mintingToken: {},
      chosenCollection: {},
      generatedMetadata: {},
      newPrimaryVariants: [],
      newSecondaryVariants: [],
      validationErrors: [],
      showingReview: false,
      skipCloudinary: false,
      reviewData: {}
    }
  },
  computed: {
    ...mapGetters([
      'userProfile',
      'mintingData',
      'mintingCollection',
      'tokenContracts',
      'globalSettings',
      'allSeries'
    ]),
    computedId () {
      if (!this.mintingToken.typePrimary) return undefined
      const secondary = this.mintingToken.typeSecondary && this.mintingToken.typeSecondary.id ? `.${this.mintingToken.typeSecondary.id}` : ''
      return `${this.mintingToken.typePrimary.id}${secondary}`
    }
  },
  methods: {
    ...mapActions([
      'loadAllSeries',
      'tokenContractsListener'
    ]),

    ...mapMutations([
      'setLoading',
      'setGlobalState',
      'setMintingCollection'
    ]),

    back () {
      this.$router.push('/mint-collection')
    },

    chooseCollection () {
      this.mintingToken.cap = this.chosenCollection.defaultCap || undefined
      if (this.chosenCollection.tokenProperties?.length) {
        this.$refs.tokenProperties.setProperties(this.chosenCollection.tokenProperties)
      }
    },

    formValid () {
      this.validationErrors = []
      if (!this.chosenCollection || !this.chosenCollection.id) {
        this.validationErrors.push('The token needs to be assigned to a collection.')
      }
      if (!this.mintingToken.typePrimary || !this.mintingToken.typeSecondary) {
        this.validationErrors.push('The new token should have both primary and secondary types.')
      }
      if (!this.mintingToken.name) {
        this.validationErrors.push('A name for the new token is required.')
      }
      if (!this.mintingData.tokenThumbData || !this.mintingData.tokenImageData) {
        this.validationErrors.push('The token is missing some required media.')
      }
      if (!this.mintingToken.cap) {
        this.validationErrors.push('Please set an issuance cap for this token.')
      }
      if (!this.validationErrors.length) {
        return true
      } else {
        return false
      }
    },

    generateReviewData () {
      this.initMetadata()
      this.reviewData = {
        id: this.computedId,
        tokenAddress: this.chosenCollection.id,
        cap: this.mintingToken.cap,
        metadata: this.generatedMetadata
      }
    },

    /**
     * Save any new variants defined by the user in the VariantsEditor above
     * @param {string} type - should be either "primary" or "secondary"
     */
    async saveNewVariants (type) {
      this.setLoading(true)
      console.log(`Save ${type}`)
      const currentVariants = { ...this.chosenCollection.variants }
      if (!currentVariants.primary) currentVariants.primary = []
      if (!currentVariants.secondary) currentVariants.secondary = []
      if (type === 'primary') {
        for (const i in this.newPrimaryVariants) {
          const variant = this.newPrimaryVariants[i]
          if (variant.image && variant.image.rawData) {
            try {
              await this.uploadPrimaryVariantMedia(this.chosenCollection.id, variant)
            } catch (err) {
              this.addNotification('Error uploading variant media.', 'error')
              this.setLoading(false)
              throw err
            }
          }
          if (variant.name && variant.id) {
            if (currentVariants.primary.filter(v => v.id === variant.id).length > 0) {
              this.addNotification(`The primary variant ID ${variant.id} already exists.`, 'error')
            } else {
              if (variant.image && variant.image.rawData) {
                variant.hasImage = true
                delete variant.image
              }
              currentVariants.primary.push(variant)
            }
          }
        }
      } else if (type === 'secondary') {
        this.newSecondaryVariants.forEach((variant) => {
          if (variant.name && variant.id) {
            if (currentVariants.secondary.filter(v => v.id === variant.id).length > 0) {
              this.addNotification(`The secondary variant ID ${variant.id} already exists.`, 'error')
            } else {
              currentVariants.secondary.push(variant)
            }
          }
        })
      }
      try {
        // Update the variants for the target collection in Firestore
        await setDoc(doc(this.$fb.db, 'token-contracts', this.chosenCollection.id), {
          variants: currentVariants
        }, { merge: true })
      } catch (err) {
        console.log(err)
        this.addNotification('Error adding new type.', 'error')
      }
      if (type === 'primary') { this.newPrimaryVariants = [] }
      if (type === 'secondary') { this.newSecondaryVariants = [] }
      this.setLoading(false)
    },

    initiateReview () {
      if (!this.formValid()) {
        console.log('The form had errors.')
        return
      }
      this.generateReviewData()
      this.showingReview = true
    },
    async publish () {
      this.showingReview = false
      if (!this.formValid()) {
        console.log('The form had errors.')
        return
      }
      this.setLoading(true)
      this.initMetadata()

      if (!this.skipCloudinary) {
        try {
          this.setGlobalState({ target: 'loadingStatus', val: 'Uploading your assets. Please don\'t close this browser window.' })
          await this.generateTokenTypeUtilities()
        } catch (err) {
          this.setLoading(false)
          this.addNotification('Error publishing assets.', 'error')
          throw err
        }
      }

      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading your assets to IPFS. Please don\'t close this browser window.' })
        await this.publishTokenAssets()
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error publishing type assets to IPFS.', 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Uploading metadata to IPFS. Please don\'t close this browser window.' })
        await this.publishJsonData()
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error publishing JSON data to IPFS.', 'error')
        throw err
      }
      try {
        this.setGlobalState({ target: 'loadingStatus', val: 'Minting new token type. This may take some time.' })
        const tokenTypeData = {
          id: this.computedId,
          tokenAddress: this.chosenCollection.id,
          cap: this.mintingToken.cap,
          metadata: this.generatedMetadata,
          computedId: this.computedId
        }
        console.log('Creating new token type with data: ', tokenTypeData)
        const res = await this.fbCall('minting-newTokenType', tokenTypeData)
        console.log(res)
      } catch (err) {
        this.setLoading(false)
        this.addNotification('Error adding new token type.', 'error')
        throw err
      }
      this.setLoading(false)
      this.setGlobalState({ target: 'loadingStatus', val: '' })
      this.addNotification('Successfully added new token type.', 'success', false)
    },

    /**
     * Get simple metadata available in form.
     */
    initMetadata () {
      // TODO: Some of this metadata population should probably be moved to the back end,
      // So that it can't be tampered with (or at least it should be validated)
      this.generatedMetadata = {
        name: this.mintingToken.name,
        description: this.mintingToken.description || '',
        attributes: this.mintingToken.attributes,
        properties: this.mintingToken.properties,
        cap: this.mintingToken.cap,
        order: this.mintingToken.order,
        seriesOrder: this.mintingToken.seriesOrder,
        typePrimary: this.mintingToken.typePrimary,
        typeSecondary: this.mintingToken.typeSecondary,
        publisher: this.globalSettings.marketName || '',
        mint: 'BLKDRP',
        collectionGroup: this.chosenCollection.collectionGroup?.name || ''
      }
      if (this.mintingToken.series) {
        const { id, name } = this.mintingToken.series
        this.generatedMetadata.series = { id, name }
      }
      if (this.mintingToken.legalUrl) {
        this.generatedMetadata.termsAndConditions = this.mintingToken.legalUrl
      } else if (this.globalSettings.defaultTermsAndConditions) {
        this.generatedMetadata.termsAndConditions = this.globalSettings.defaultTermsAndConditions
      }
      if (this.mintingData.tokenModelData) {
        // First, save the viewer settings to metadata...
        this.generatedMetadata.viewerSettings = this.mintingToken.viewerSettings
      }
    },

    /**
     * Generate token type media utilities to be used for display in the Market
     * These are added to "off-chain" storage so that assets can be pulled quickly when necessary,
     * rather than waiting on long loads from the IPFS address stored in metadata.
     */
    async generateTokenTypeUtilities () {
      const tokenAddress = this.chosenCollection.id
      console.log(`Uploading token type utility assets to generated-assets/${tokenAddress}/${this.computedId}/`)
      const rootPath = `generated-assets/${tokenAddress}/${this.computedId}`
      await this.fbCall('cloudinary-uploadAsset', {
        path: `${rootPath}/thumb`,
        asset: this.mintingData.tokenThumbData.rawData
      })

      await this.fbCall('cloudinary-uploadAsset', {
        path: `${rootPath}/image`,
        asset: this.mintingData.tokenImageData.rawData
      })

      if (this.mintingData.tokenFullImageData) {
        await this.fbCall('cloudinary-uploadAsset', {
          path: `${rootPath}/fullImage`,
          asset: this.mintingData.tokenFullImageData.rawData
        })
      }

      if (this.mintingData.tokenUnclaimedImageData) {
        await this.fbCall('cloudinary-uploadAsset', {
          path: `${rootPath}/unclaimedImage`,
          asset: this.mintingData.tokenUnclaimedImageData.rawData
        })
      }

      if (this.mintingData.tokenVideoData) {
        await this.fbCall('cloudinary-uploadAsset', {
          path: `${rootPath}/video`,
          asset: this.mintingData.tokenVideoData.rawData
        })
      }

      if (this.mintingData.tokenModelData) {
        await this.fbCall('cloudinary-uploadAsset', {
          path: `${rootPath}/model`,
          asset: this.mintingData.tokenModelData.rawData
        })
      }

      console.log('Finished generating token type utilities.')
    },

    /**
     * Publish relevant assets to IPFS.
     */
    async publishTokenAssets () {
      console.log('Publishing token assets.')
      // Generate references for required image assets.
      const thumbHash = await this.clientSideIpfsUpload(this.mintingData.tokenThumbData.fileData)
      this.generatedMetadata.thumbHash = thumbHash
      this.generatedMetadata.thumb = this.getIpfsUrl(thumbHash)

      const imageHash = await this.clientSideIpfsUpload(this.mintingData.tokenImageData.fileData)
      this.generatedMetadata.imageHash = imageHash
      this.generatedMetadata.image = this.getIpfsUrl(imageHash)

      // Generate references for a full res image asset, if required.
      if (this.mintingData.tokenFullImageData) {
        const fullImageHash = await this.clientSideIpfsUpload(this.mintingData.tokenFullImageData.fileData)
        this.generatedMetadata.fullImageHash = fullImageHash
        this.generatedMetadata.fullImage = this.getIpfsUrl(fullImageHash)
      }

      // Generate references for video asset, if required.
      if (this.mintingData.tokenVideoData) {
        const videoHash = await this.clientSideIpfsUpload(this.mintingData.tokenVideoData.fileData)
        this.generatedMetadata.videoHash = videoHash
        this.generatedMetadata.video = this.getIpfsUrl(videoHash)
      }

      // Generate references for 3D model asset, if required.
      if (this.mintingData.tokenModelData) {
        // ...then manage the media
        const modelHash = await this.clientSideIpfsUpload(this.mintingData.tokenModelData.fileData)
        this.generatedMetadata.modelHash = modelHash
        this.generatedMetadata.model = this.getIpfsUrl(modelHash)
      }
    },

    /**
     * Publish the JSON data to IPFS
     */
    async publishJsonData () {
      console.log('Publishing token metadata', this.generatedMetadata)
      try {
        const hash = await this.addAssetToIpfs(this.createJsonBlob(this.generatedMetadata))
        this.generatedMetadata.metadataUrl = this.getIpfsUrl(hash)
        console.log('Published JSON data: ', this.generatedMetadata.metadataUrl)
      } catch (err) {
        throw console.log(err)
      }
    }
  },

  created () {
    this.tokenContractsListener()
    this.loadAllSeries(true)
  }
}
</script>

<style scoped lang="scss">
.input-container.id {
  .row {
    gap: $space-m;
    .field-container {
      flex: 1;
      &:nth-child(1) {
        flex: 3
      }
    }
  }
}

.skip-cloudinary {
  margin-bottom: $space-m;
}
</style>
