<template>
  <Modal
    title="Review Mint"
    subTitle="Please ensure this info is correct"
    large
    :onConfirm="confirmMint"
    @close="$emit('close')"
  >
    <div class="review">
      <pre class="review__data">
{{ JSON.stringify(this.mintingData, undefined, 2) }}
      </pre>
    </div>
  </Modal>
</template>

<script>
export default {
  props: {
    mintingData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    confirmMint () {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
.review {
  background-color: $blk-grey-6;
  max-height: 340px;
  padding: $space-s;
  overflow-y: scroll;
  &__data {
    text-align: left;
    font-size: $font-size-xs;
  }
}
</style>
